import axios from 'axios'
import { Toast } from 'antd-mobile'
// import {getAuthUrl} from './'

const makeFormData = (params) => {
  const form = new window.FormData()
  for (const key of Object.keys(params)) {
    form.append(key, params[key])
  }
  return form
}

axios.interceptors.request.use((config) => {
  config.headers['max-http-header-size'] = '2MB';
  config.headers['contentType'] = 'application/json;charset=UTF-8';

   

  if (config.contentType === 'form-data') {
    config.data = makeFormData(config.data)
  }

  return config
}, (error) => {
  return Promise.reject(error)
})

axios.interceptors.response.use((res) => {
  res.headers['contentType'] = 'application/json;charset=UTF-8';

  if(res && res.data && res.data.code.toString() !== '200') {
    Toast.info(res.data.message , 2, null, false);
    return res.data
  }
  
  return res.data

}, (error) => {
  // 错误静默处理，通过判断payload来判断数据结果
  if (error.response && error.response.data.message) {
    Toast.info(error.response.data.message , 2, null, false);
    return Promise.reject(error.response.message)
  } else if (error.response) {
    return Promise.reject(`${error.response.status}:${error.response.statusText}`)
  } else {
    return Promise.reject('服务处理异常')
  }
})

export default axios
