
const LOCK_ORDER = 'order/LOCK_ORDER'
const LOCK_ORDER_SUCCESS = 'order/LOCK_ORDER_SUCCESS'
const LOCK_ORDER_FAILURE = 'order/LOCK_ORDER_FAILURE'

const PAY = 'order/PAY'
const PAY_SUCCESS = 'order/PAY_SUCCESS'
const PAY_FAILURE = 'order/PAY_FAILURE'


const QUERY_ORDER_STATUS = 'order/QUERY_ORDER_STATUS'
const QUERY_ORDER_STATUS_SUCCESS = 'order/QUERY_ORDER_STATUS_SUCCESS'
const QUERY_ORDER_STATUS_FAILURE = 'order/QUERY_ORDER_STATUS_FAILURE'

const QUERY_INTEGRAL_ORDER_STATUS = 'order/QUERY_INTEGRAL_ORDER_STATUS'
const QUERY_INTEGRAL_ORDER_STATUS_SUCCESS = 'order/QUERY_INTEGRAL_ORDER_STATUS_SUCCESS'
const QUERY_INTEGRAL_ORDER_STATUS_FAILURE = 'order/QUERY_INTEGRAL_ORDER_STATUS_FAILURE'


const api = '/public/service'
// const api ='/api/order'
// /api/order

export default (state = {signing: true}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export const lockOrder = ({orderNo}) => {
  return {
    types: [LOCK_ORDER, LOCK_ORDER_SUCCESS, LOCK_ORDER_FAILURE],
    promise: apiClient => apiClient.get(`${api}/ume/order/lock?orderNo=${orderNo}`)
  }
}

export const pay = ({amount, channel, orderNo, cardValid, cardCvc, cardNo, callbackUrl}) => {
  let params = {amount,channel, orderNo};
  if(cardValid) {
    params.cardValid = cardValid
  }
  if(cardCvc) {
    params.cardCvc = cardCvc
  }
  if(cardNo) {
    params.cardNo = cardNo
  }
  if(callbackUrl) {
    params.callbackUrl = callbackUrl
  }
  return {
    types: [PAY, PAY_SUCCESS, PAY_FAILURE],
    promise: apiClient => apiClient.post(`${api}/ume/package/pay/index`, params)
  }
}


export const queryOrderStatus = (orderNo) => {
  return {
    types: [QUERY_ORDER_STATUS, QUERY_ORDER_STATUS_SUCCESS,QUERY_ORDER_STATUS_FAILURE],
    promise: apiClient => apiClient.get(`${api}/ume/package/order/${orderNo}`)
  } 
}

export const queryIntegralOrderStatus = (orderNo) => {
  return {
    types: [QUERY_INTEGRAL_ORDER_STATUS, QUERY_INTEGRAL_ORDER_STATUS_SUCCESS,QUERY_INTEGRAL_ORDER_STATUS_FAILURE],
    promise: apiClient => apiClient.get(`${api}/ume/integral/order/${orderNo}`)
  } 
}
