import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Icon } from "antd-mobile"
import Ume_Logo from './resoureces/images//ume-logo.png'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import store from './stores'
import  './index.css'

const App = lazy(() => import('./components/page/index/App'))
const Wait = lazy(() => import('./components/page/index/Wait'))
const Failure = lazy(() => import('./components/page/result/Failure'))
const Success = lazy(() => import('./components/page/result/Success'))


import './resoureces/css/reset.css'


ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Suspense fallback={<Loading/>}>
        <Switch>
          <Route exact path="/" component={App}/>
          <Route exact path="/index" component={App}/>
          <Route exact path="/wait" component={Wait}/>
          <Route exact path="/success" component={Success}/>
          <Route exact path="/failure" component={Failure}/>
        </Switch>
      </Suspense>
    </Router>
  </Provider>,
  document.getElementById('root')
)


function Loading(props) {
  const { title = '資源加載中...' } = props;
  return <div className='async_loading'>
    <img src={Ume_Logo} alt="" />
    <p>{title}</p>
    <Icon type="loading" />
  </div>
}